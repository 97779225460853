
.border-bottoms>div>div {
    border-bottom:2px solid #a3a0a0;
}
.test{
    color: red;
    text-align: center;
}
/* .react-grid-HeaderCell {
    padding: 0px;
} */
.react-grid-Cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    text-align:center;
  
}
#rentmonies div.react-grid-Row div.react-grid-Cell {
  padding-left: 0px ;
  padding-right: 0px;

  line-height: 2.4;
  text-align: center;
}

.react-grid-Cell__value{
    height:40px
}
.faceicon{
    width: 30px;
    max-height: 30px;
    border-radius: 50%;
    margin:3px;
}
.react-grid-Grid{
 
    background-color: "#ddd";
}
.rdg-editor-container {
     z-index:10050 !important;
    /* position:absolute !important; */
    }



   .adjustment .rdg-cell-action {
    float: right;
    height: 100%;
}
    .react-grid-Cell {
padding:0
    }


#contractcheck .rdg-cell-action-button {
    padding-right: 10px;
}

#seisanlist .MuiFormControl-marginNormal {
margin:0 !important;
}

#seisanlist .MuiIconButton-root {
padding:5px !important;
}

#seisanlist .public_fixedDataTableCell_cellContent {
    padding: 2px;
}
#seisantable .MuiTableCell-sizeSmall {
    padding:2px;
}

/* #seisanlist .public_fixedDataTableColumnResizerLine_main {
    border-color: #d3d3d3;
} */

/* #seisanlist .fixedDataTableColumnResizerLineLayout_main {
 
    /* border-right-style: solid; */
    /* border-right-width: 1px; */
    /* box-sizing: border-box; */
    /* position: absolute; */
    /* z-index: 10; */
    /* pointer-events: none; */
/* }  */
    /* 
/* .react-grid-Canvas { position:relative !important; } */
/* .react-grid-Cell{ z-index: 10050  } */

.row-highlight-orange>div>div{ background-color:#ffe6b3 !important; }


.row-highlight-pink>div>div{ background-color:pink !important; }

.row-highlight-green>div>div{ background-color:#c8fac8 !important; }

.row-highlight-white>div>div{ background-color:#FFF !important; }
.row-highlight-gray>div>div{ background-color:#d3d3d3 !important; }

.row-highlight-yellow>div>div{ background-color:#ffe6b3 !important; }


.row-highlight-yellowgreen>div>div{ background-color:#d7ffb3 !important; }

.row-highlight-smoke>div>div{ background-color:#f5f5f5 !important; }


.pushed-row {
  background:#87cefa;
}

.yotei-row {
  background:#f0e68c;
}

.irai-row {
  background:#ee82ee;
}

.none-row {
  display:none;
}
/* #contractcheck div.react-grid-Row div.react-grid-Cell:nth-of-type(1),
#contractcheck div.react-grid-Row div.react-grid-Cell:nth-of-type(2){
  background-color: #f2f9de;
} */


/* #contractcheck div.react-grid-Row div.react-grid-Cell {

  line-height: 3; */

/* } */