.label-info {
    background-color: #5bc0de;
}

.label-default {
    background-color: #777;
}
.label-warning {
    background-color: #f0ad4e;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    margin:3px;
}

.newoutai
.dropzone {
     min-height: 60px;
}

.modal-request{
    width:100% !important;
    max-width:2000px !important;
}


.animetion_box{
  animation: flash 1s linear infinite;
  background:#ff007f;
}

@keyframes flash {
  0%,100% {
    opacity: 1;
        background:#ff75ff;
  }

  50% {
    opacity: 1;
        background:#ff0000;

  }
}